<template>
  <div class="login">
    <h1>{{ $t('views.oiclogin.login') }}</h1>
  </div>
</template>

<script>
import LoginService from '@/api-services/login.service'

export default {
  name: 'net_suite_login',
  methods: {
    redirect_after_login() {
      let redir = window.localStorage.getItem('oic_redir_after_login') || this.$store.state.default_login_page
      if (redir === '/' && this.$store.state.default_login_page !== '/') {
        redir = this.$store.state.default_login_page
      }
      window.localStorage.removeItem('oic_redir_after_login')
      this.$nextTick(() => {
        this.$router.push(redir)
      })
    }
  },
  created: async function () {
    const self = this

    if ('code' in this.$route.query) {
      const payload = this.$route.fullPath.replace(this.$route.path, '')
      LoginService.receive_netdb_token(payload).then(async (response) => {
        if (response.data.token != null) {
          self.$store.commit('login', {
            token: response.data.token
          })
          await self.$store.dispatch('refresh_session_info')
          this.redirect_after_login()
        } else {
          // TODO: invalid login
        }
      }).catch(async (e) => {
        console.log('Login failed with', e)
        if (e.response.status === 400) {
          if (e.response.data.detail === 'invalid_token') {
            // user most likely pressed "Back" in the browser ==> redirect to after-login target
            this.redirect_after_login()
            return
          }
        }
        throw e
      })
    }
  }
}
</script>
